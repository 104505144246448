// import { filterActions } from 'redux-ignore';
//redux-ignore //  https://github.com/omnidan/redux-ignore ## can be used to avoid performance problems in large apps by skipping reducer subtrees
import { combineReducers } from 'redux';

// import product from './data/productReducer';
import cartReducer from './data/cartReducer';
import productReducer from './data/productReducer';
import productsListReducer from './data/productsListReducer';
import productListContainerReducer from './ui/productListContainerReducer';
import sessionReducer from './data/sessionReducer';
import navbarReducer from './ui/navbarReducer';

export default combineReducers({
  cart: cartReducer,
  navbar: navbarReducer,
  //   common: combineReducers({
  //     category,
  //   }),
  // session: combineReducers({
  //   sessionReducer,
  // }),
  //   homePage: combineReducers({
  //     ui: combineReducers({
  //       homeContainer,
  //     }),
  //     data: combineReducers({
  //         product: filterActions(product, action => action.target === targets.HOME_PAGE),
  //     }),
  //   }),
  productsList: combineReducers({
    ui: combineReducers({
      productListContainerReducer,
    }),
    data: combineReducers({ productsListReducer }),
  }),
  productPage: combineReducers({
    data: combineReducers({ productReducer }),
  }),
  //   loginPage: combineReducers({
  //     ui: combineReducers({
  //       loginContainer,
  //     }),
  //     data: combineReducers({
  //       session: sessionReducer,
  //     }),
  //   }),
});
