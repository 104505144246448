import {
  AppRouterProvider,
  UiThemeProvider,
  StoreProvider,
} from '../providers';
export const mergeProviders =
  (...providers) =>
  ({ children }) => {
    return providers.reduceRight(
      (child, Provider) => <Provider>{child}</Provider>,
      children
    );
  };
export const AllProviders = mergeProviders(
  UiThemeProvider,
  StoreProvider,
  AppRouterProvider
);
