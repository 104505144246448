import { productActionTypes } from '../../types/product';

/** data {
   "id": 2,
   "title": "Mens Casual Premium Slim Fit T-Shirts ",
   "price": 22.3,
   "description": "Slim-fitting style, contrast raglan long sleeve, three-button henley placket, light weight & soft fabric for breathable and comfortable wearing. And Solid stitched shirts with round neck made for durability and a great fit for casual fashion wear and diehard baseball fans. The Henley style round neckline includes a three-button placket.",
   "category": "men's clothing",
   "image": "https://fakestoreapi.com/img/71-3HjGNDUL._AC_SY879._SX._UX._SY._UY_.jpg",
   "rating": {
       "rate": 4.1,
       "count": 259
   }
 }

 */
const initialState = {
  isFetching: false,
  data: null,
  fetchError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case productActionTypes.GET_PRODUCT_BEGIN: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case productActionTypes.GET_PRODUCT_SUCCESS: {
      const { product } = action.payload;

      return {
        ...state,
        data: product.data,
        fetchError: null,
        isFetching: false,
      };
    }
    case productActionTypes.GET_PRODUCT_FAILURE: {
      const { error: fetchError } = action.payload;

      return {
        ...state,
        fetchError,
        isFetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
