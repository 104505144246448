import { navbarActionTypes } from '../../types/navbar';

export const openDialog = (product) => {
  return {
    type: navbarActionTypes.OPEN_DIALOG,
  };
};
export const closeDialog = () => {
  return {
    type: navbarActionTypes.CLOSE_DIALOG,
  };
};
