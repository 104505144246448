import React from 'react';
import { Outlet } from 'react-router-dom';

import './Market.css';
import { BaseNavbar } from '../../components';
import { Box } from '@mui/material';

export function Market() {
  return (
    <>
      <BaseNavbar></BaseNavbar>
      <Box component="div" sx={{ backgroundColor: 'background.paper' }}>
        <Outlet />
      </Box>
    </>
  );
}
