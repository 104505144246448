import { Market } from '../layouts';
import { Home } from '../pages/Home';
import { Products } from '../pages/Products';
import { Product } from '../pages/Product';

export const routes = [
  {
    path: '/',
    name: 'market',
    element: <Market />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: '/products',
        name: 'products',
        element: <Products />,
      },
      {
        path: '/products/:id',
        name: 'product',
        element: <Product />,
      },
    ],
  },
];
