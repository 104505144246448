import { cartActionTypes } from '../../types/cart';
const initialState = [];

export default function (state = initialState, action) {
  const product = action.payload;
  const isExist = (p) => state.find((x) => x.id === p.id);
  switch (action.type) {
    case cartActionTypes.ADD_ITEM:
      if (isExist(product)) {
        return state.map((x) =>
          x.id === product.id ? { ...x, qty: x.qty + 1 } : x
        );
      } else {
        const product = action.payload;
        return [
          ...state,
          {
            ...product,
            qty: 1,
          },
        ];
      }
    case cartActionTypes.REMOVE_ITEM:
      if (isExist(product)) {
        return state.filter((x) => x.id !== product.id);
      } else {
        return state;
      }
    case cartActionTypes.REMOVE_ALL_ITEMS:
      return [];
    default:
      return state;
  }
}
