
import { filterProductsActionTypes } from '../../types/product';

const initialState = {
  activeFilter: 'All',
  categories: [
    'All',
    "women's clothing",
    "men's clothing",
    'jewelery',
    'electronics',
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case filterProductsActionTypes.FILTER_PRODUCTS_BY_CATEGORY: {
      const { category } = action.payload;
      return {
        ...state,
        activeFilter: category,
      };
    }
    case filterProductsActionTypes.RESET_FILTER: {
      const { category } = action.payload;
      return {
        ...state,
        activeFilter: category || 'All',
      };
    }
    default: {
      return state;
    }
  }
};
