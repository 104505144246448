import { filterProductsActionTypes } from '../../../types/product';

export const filterByCategory = (category) => {
  return {
    type: filterProductsActionTypes.FILTER_PRODUCTS_BY_CATEGORY,
    payload: category,
  };
};

export const resetFilter = (category = 'All') => {
  return {
    type: filterProductsActionTypes.RESET_FILTER,
    payload: category,
  };
};
