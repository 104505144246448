import React from 'react';
import { ProductsListContainer } from '../../components';
import './Products.css';
export function Products() {
  return (
    <>
      <ProductsListContainer></ProductsListContainer>
    </>
  );
}
