import { apiClient } from '../../libraries/apiClient';
import {
  productActionTypes,
  productsActionTypes,
  productsByCategoryActionTypes,
} from '../types/product';

export const getProduct = (productId) => async (dispatch) => {
  dispatch({
    type: productActionTypes.GET_PRODUCT_BEGIN,
  });
  // console.log('get product');
  try {
    const product = await apiClient.get(`/products/${productId}`, {});

    dispatch({
      type: productActionTypes.GET_PRODUCT_SUCCESS,
      payload: {
        product: product,
      },
    });
    // console.log(product);
  } catch (error) {
    console.error(error);
    dispatch({
      type: productActionTypes.GET_PRODUCT_FAILURE,
      payload: {
        error,
      },
    });
  }
};

export const getProducts = (params) => async (dispatch) => {
  dispatch({
    type: productsActionTypes.GET_PRODUCTS_BEGIN,
  });
  // console.log('get product');
  try {
    let products;
    if (params && params.category && params.category !== 'All')
      products = await apiClient.get(`/products/category/${params.category}`);
    // {limit ,sort}
    else products = await apiClient.get(`/products`); // {limit ,sort}

    dispatch({
      type: productsActionTypes.GET_PRODUCTS_SUCCESS,
      payload: {
        products: products,
      },
    });
    // console.log(products);
  } catch (error) {
    console.error(error);
    dispatch({
      type: productsActionTypes.GET_PRODUCTS_FAILURE,
      payload: {
        error,
      },
    });
  }
};
export const getProductsByCategory = (category, params) => async (dispatch) => {
  dispatch({
    type: productsByCategoryActionTypes.GET_PRODUCTS_BY_CATEGORY_BEGIN,
  });
  // console.log('get product');
  try {
    const products = await apiClient.get(`/products/category/${category}`, {
      params,
    }); // {limit ,sort}

    dispatch({
      type: productsByCategoryActionTypes.GET_PRODUCTS_BY_CATEGORY_SUCCESS,
      payload: {
        products: products,
      },
    });
    // console.log(products);
  } catch (error) {
    console.error(error);
    dispatch({
      type: productsByCategoryActionTypes.GET_PRODUCTS_BY_CATEGORY_FAILURE,
      payload: {
        error,
      },
    });
  }
};
