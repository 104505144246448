import { productsActionTypes,productsByCategoryActionTypes } from '../../types/product';


const initialState = {
  isFetching: false,
  data: null,
  fetchError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case productsActionTypes.GET_PRODUCTS_BEGIN: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case productsActionTypes.GET_PRODUCTS_SUCCESS: {
      const { products } = action.payload;

      return {
        ...state,
        data: products.data,
        fetchError: null,
        isFetching: false,
      };
    }
    case productsActionTypes.GET_PRODUCTS_FAILURE: {
      const { error: fetchError } = action.payload;

      return {
        ...state,
        fetchError,
        isFetching: false,
      };
    }
    default: {
      return state;
    }
  }
};
