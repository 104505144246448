import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './BaseNavbar.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ShoppingCart from '@mui/icons-material/ShoppingCart';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LoginIcon from '@mui/icons-material/Login';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector, useDispatch } from '../../../libraries/store';
import { useNavigate } from 'react-router-dom';
// dialog imports
import { Transition } from '.';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  openDialog,
  closeDialog,
} from '../../../store/actions/ui/navbarActions';
import { emptyCart } from '../../../store/actions/cartActions';
import { useWindowSize } from '../../../hooks';

export function BaseNavbar() {
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  // console.log('cart', cart);
  // console.log('cart.length', cart.length);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Todo auto close after resize screen
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (windowSize.width > 899 && open) {
      handleClose();
    }
  }, [windowSize, open]);

  const navbarState = useSelector((state) => state.navbar);
  const { isDialogOpen } = navbarState;
  // console.log(navbarState);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        // style={{ backgroundColor: '#ede4e4' }}
        elevation={0}
      >
        <Toolbar>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              },
              alignItems: 'center',
            }}
          >
            {/* logo */}
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', cursor: 'pointer' }}
              component="div"
              onClick={() => navigate(`/`)}
              color="black.main"
            >
              {process.env.REACT_APP_MARKET}
            </Typography>

            {/* mobile menu icon */}
            <Box
              component="div"
              sx={{
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                sx={{ display: { xs: 'block', md: 'none' } }}
                id="nav-menu-controller"
                aria-controls={open ? 'nav-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            {/* mobile menu list */}
            <Menu
              classes={{
                root: {
                  background: 'primary.main',
                  backgroundColor: 'primary.main',
                  color: 'primary.main',
                },
                paper: {
                  background: 'primary',
                  backgroundColor: 'primary',
                  color: 'primary',
                },
              }}
              id="nav-menu"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              elevation={0}
              aria-labelledby="nav-menu-controller"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                // backgroundColor: 'primary.main',
                display: { xs: 'block', md: 'none' },
              }}
            >
              {/* component={NavLink} */}

              <NavLink to="/" style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    sx={{
                      ...(isActive
                        ? { color: 'black.main' }
                        : { color: 'secondary.main' }),
                    }}
                  >
                    Market
                  </MenuItem>
                )}
              </NavLink>
              <NavLink to="/products" style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    sx={{
                      ...(isActive
                        ? { color: 'black.main' }
                        : { color: 'secondary.main' }),
                    }}
                  >
                    Products
                  </MenuItem>
                )}
              </NavLink>
              <hr />

              <MenuItem
                sx={{
                  color: 'secondary.main',
                }}
              >
                <ListItemIcon
                  sx={{
                    color: 'secondary.main',
                  }}
                  onClick={() => dispatch(emptyCart())}
                >
                  <ShoppingCart style={{ transform: 'rotate(45deg)' }} />
                </ListItemIcon>
                <ListItemText>Empty Cart</ListItemText>
              </MenuItem>
              <MenuItem
                sx={{
                  color: 'secondary.main',
                }}
              >
                <ListItemIcon
                  sx={{
                    color: 'secondary.main',
                  }}
                  onClick={() => dispatch(openDialog())}
                >
                  <ShoppingCart />
                </ListItemIcon>
                <ListItemText>Cart ({cart?.length || 0})</ListItemText>
              </MenuItem>
            </Menu>

            {/* NavLinks */}
            <Box
              component="div"
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 2,
              }}
            >
              <NavLink to="/" style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    sx={{
                      ...(isActive
                        ? { color: 'black.main' }
                        : { color: 'secondary.main' }),
                    }}
                  >
                    Market
                  </MenuItem>
                )}
              </NavLink>

              <NavLink to="/products" style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <MenuItem
                    sx={{
                      ...(isActive
                        ? { color: 'black.main' }
                        : { color: 'secondary.main' }),
                    }}
                  >
                    Products
                  </MenuItem>
                )}
              </NavLink>
            </Box>

            {/*  actions */}
            <Box
              component="div"
              sx={{
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'flex-end',
                gap: 2,
              }}
            >
              <Button
                variant="outlined"
                startIcon={
                  <ShoppingCart style={{ transform: 'rotate(45deg)' }} />
                }
                color="primary"
                onClick={() => dispatch(emptyCart())}
              >
                Empty Cart
              </Button>
              <Button
                variant="outlined"
                startIcon={<ShoppingCart />}
                color="primary"
                onClick={() => dispatch(openDialog())}
              >
                Cart ({cart?.length || 0})
              </Button>
            </Box>

            {/*  */}
            <Dialog
              open={isDialogOpen}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => dispatch(closeDialog())}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle color="black.main">Order Summary</DialogTitle>
              <DialogContent
                sx={{ color: 'text.secondary' }}
                id="alert-dialog-slide-description"
              >
                {/* <Box sx={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <DialogContentText color="secondary">Name</DialogContentText>
                  <DialogContentText color="secondary">Price</DialogContentText>
                  <DialogContentText color="secondary">Qty</DialogContentText>
                </Box> */}

                {cart && cart.length ? (
                  cart.map((item) => (
                    <>
                      <Box
                        key={item.id}
                        sx={{
                          display: 'flex',
                          gap: 6,
                          alignItems: 'center',
                        }}
                      >
                        <DialogContentText
                          color="secondary"
                          style={{
                            maxWidth: '50%',
                            width: '50%',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {item.title}
                        </DialogContentText>
                        <DialogContentText
                          color="secondary"
                          style={{
                            maxWidth: '25%',
                            width: '25%',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.price} AED
                        </DialogContentText>
                        <DialogContentText
                          color="secondary"
                          style={{
                            maxWidth: '25%',
                            width: '25%',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          X {item.qty}
                        </DialogContentText>
                      </Box>
                      <Box
                        component="hr"
                        sx={{
                          border: 'solid 1px',
                          borderColor: 'black.main',
                          backgroundColor: 'black.main',
                        }}
                      ></Box>
                    </>
                  ))
                ) : (
                  <DialogContentText
                    color="secondary"
                    sx={{ textAlign: 'center' }}
                  >
                    Your Cart Is Empty
                  </DialogContentText>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => dispatch(closeDialog())}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            {/*  */}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
