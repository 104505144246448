import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { themeFactory, whiteLabels } from '../config/themes';

const label = process.env.REACT_APP_MARKET;
const theme = createTheme(themeFactory(whiteLabels(label)));
// import from config // add support white label in config
// console.log(MARKET);
// console.log(process.env);

export const UiThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
    {/* <CssBaseline /> */}
  </ThemeProvider>
);
