import { navbarActionTypes } from '../../types/navbar';

const initialState = {
  isDialogOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case navbarActionTypes.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
      };
    }
    case navbarActionTypes.CLOSE_DIALOG: {
      return {
        ...state,
        isDialogOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};
