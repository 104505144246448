import { cartActionTypes } from '../types/cart';

export const addCart = (product) => {
  return {
    type: cartActionTypes.ADD_ITEM,
    payload: product,
  };
};
export const deleteCart = (product) => {
  return {
    type: cartActionTypes.REMOVE_ITEM,
    payload: product,
  };
};
export const emptyCart = () => {
  return {
    type: cartActionTypes.REMOVE_ALL_ITEMS,
  };
};
