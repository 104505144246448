import React from 'react';
import { Hero, ProductsListContainer } from '../../components';
import './Home.css';
export function Home() {
  return (
    <>
      <Hero />
      <ProductsListContainer></ProductsListContainer>
    </>
  );
}
