export const productActionTypes = {
  // PRODUCT
  GET_PRODUCT_BEGIN: 'GET_PRODUCT_BEGIN',
  GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: 'GET_PRODUCT_FAILURE',
};
export const productsActionTypes = {
  // PRODUCTS
  GET_PRODUCTS_BEGIN: 'GET_PRODUCTS_BEGIN',
  GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',
};
export const productsByCategoryActionTypes = {
  // PRODUCTS
  GET_PRODUCTS_BY_CATEGORY_BEGIN: 'GET_PRODUCTS_BY_CATEGORY_BEGIN',
  GET_PRODUCTS_BY_CATEGORY_SUCCESS: 'GET_PRODUCTS_BY_CATEGORY_SUCCESS',
  GET_PRODUCTS_BY_CATEGORY_FAILURE: 'GET_PRODUCTS_BY_CATEGORY_FAILURE',
};

// UI
export const filterProductsActionTypes = {
  FILTER_PRODUCTS_BY_CATEGORY: 'FILTER_PRODUCTS_BY_CATEGORY',
  RESET_FILTER: 'RESET_FILTER',
};
