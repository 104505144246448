import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import './ProductCard.css';

export function ProductCard({ product }) {
  const navigate = useNavigate();

  return (
    <Card>
      <CardActionArea
        onClick={() => navigate(`/products/${product.id}`)}
        style={{ background: 'transparent' }}
      >
        <CardMedia
          component="img"
          height="140"
          image={product.image}
          alt="green iguana"
          style={{ objectFit: 'contain', background: 'white' }}
        />
        <CardContent
          style={{ minHeight: '153px' }}
          sx={{ backgroundColor: 'primary.main' }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color="black.main"
            noWrap
          >
            {product.title}
          </Typography>
          <Typography variant="body2" color="secondary.main" className="truncate-5">
            {product.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions sx={{ backgroundColor: 'primary.main' }}>
        <Button
          component={Link}
          to={`/products/${product.id}`}
          size="small"
          variant="contained"
          color="primary"
        >
          Details
        </Button>
      </CardActions> */}
    </Card>
  );
}
